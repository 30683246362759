// WARNING: DO NOT EDIT.  This file is automatically generated
// Written by aws-amplify-serverless-plugin/1.4.1 on 2021-11-05T20:59:52.146Z

const awsmobile = {
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_graphqlEndpoint: 'https://hbreskbscve75nzlnrk5qvhs2e.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_cognito_identity_pool_id: 'us-east-1:8cbc4af4-c36b-4b69-bd78-e6e2c102fa3e',
    aws_cognito_region: 'us-east-1',
    aws_project_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_8eU3cukaE',
    aws_user_pools_web_client_id: '3bupupi9ku15thqggq750vk3qj'
};

export default awsmobile;
