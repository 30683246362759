import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import '@coreui/icons/css/coreui-icons.min.css';
import 'font-awesome/css/font-awesome.min.css';
// import '../src/scss/style.css';

import { 
  // HashRouter, 
  Route, Switch, BrowserRouter } from 'react-router-dom';
import {Chart} from "react-google-charts";
import './App.css';
import './App.scss';
import Login from './views/Pages/Login';
// import Dashboard from './containers/DefaultLayout';

import AWSAppSyncClient from "aws-appsync";
import { Rehydrated } from 'aws-appsync-react';
import { ApolloProvider } from 'react-apollo';

import 
// Amplify,
{Auth, API, 
  // PubSub
} from 'aws-amplify';
import { withAuthenticator, SignIn } from 'aws-amplify-react';
import awsconfig from './aws-exports';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row
} from 'reactstrap';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
//const Login = import('./views/Pages/Login');
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

// awsconfig.aws_appsync_graphqlEndpoint = "https://fkrpvbe3sfe25i7m6x6robduka.appsync-api.us-east-1.amazonaws.com/graphql"
// awsconfig.aws_appsync_region = 'us-east-1'
// awsconfig.aws_appsync_authenticationType = 'AMAZON_COGNITO_USER_POOLS' // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id

// const GRAPHQL_API_REGION = awsconfig.aws_appsync_region
// const GRAPHQL_API_ENDPOINT_URL = awsconfig.aws_appsync_graphqlEndpoint
// const AUTH_TYPE = awsconfig.aws_appsync_authenticationType

const client = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: awsconfig.aws_appsync_authenticationType,
    // Get the currently logged in users credential.
    jwtToken: async () => (await Auth.currentSession()).getAccessToken().getJwtToken(),
  },
  // Amplify uses Amazon IAM to authorize calls to Amazon S3. This provides the relevant IAM credentials.
  complexObjectsCredentials: () => Auth.currentCredentials()
});

class App extends Component {


  constructor(props) {
    super(props);
    this.state = {
      token: '',
      selection: 'barcode',
      showBarcode: true, 
      showDate: false,
      mode: 2,
      barcode: '',
      date: '',
      features: [],
      data: ''
    };
  }

  handleSelectChange = (event) => {
    /**
     * handle select change function
     * set state value of selection, mode, showBarcode, showDate
     */
    this.setState({
      selection: event.target.value
    });
    switch(event.target.value) {
      case 'barcode':
        this.setState({
          mode: 2,
          showBarcode: true,
          showDate: false
        });
        break;
      case 'date':
        this.setState({
          mode: 1,
          showBarcode: false,
          showDate: true
        });
        break;
    }
  }

  handleBarcodeChange = (event) => {
    /**
     * handle Barcode change:
     * set state barcode value
     */
    this.setState({
      barcode: event.target.value
    });
  }

  handleDateChange = (event) => {
    /**
     * handle Date Change:
     * set state date value
     */
    this.setState({
      date: event.target.value
    });
  }

  handleFeatureSelection = (event) => {
    /**
     * handle feature selection:
     * feature selection is multiple selection
     * set value feature with all the selected options
     * list all the selected options in the selected div
     */
    const options = event.target.options;
    var value = [];
    // all the selected options
    for(var i=0; i <options.length; i++) {
      if(options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({
      features: value
    });
    const selectedFeature = <h4>{value.join(",")}</h4>;
    ReactDOM.render(selectedFeature, document.getElementById('selected'));
  }

  handleSubmit = (event) => {
    /**
     * handle query submit:
     * send ajax request with data, mode and barcode
     * on success response:
     * mode 1: 
     * process response data
     * set state data with processed data
     * show feature selection options 
     * mode 2:
     * create table based on the response data
     */
    let apiName = "apie83486d7";
    let path = '/items';
    let data = {
      headers: {},
      body: {
        date: this.state.date, // "2019-12-10",
        mode: this.state.mode,//2,
        barcode: this.state.barcode //"10337746-1b7a-11ea-bc67-2a366b75c3b1"
      }
    }
    API.post(apiName, path, data).then(response => {
      console.log('repsonse: ', response.Items);
      if(this.state.mode === 1) {
        ReactDOM.render(<h3>Waiting for data loading.</h3>, document.getElementById('featureSelection'));
        // data processing
        const chartData = this.prepareData(response.Items);
        // set data
        chartData.then(data => {
          this.setState({
            data: data
          });
          // show all the features selection
          const attributes = Object.keys(response.Items[0]);
          const options = this.createOptions(attributes);
          const featureSelection = this.createFeatureSelection(options);
          ReactDOM.render(featureSelection, document.getElementById('featureSelection'));
        }); 
      }else {
        // draw table
        const tableData = response.Items[0];
        const table = this.createTable(tableData);
        ReactDOM.render(table, document.getElementById('barcode_data'));
      }
    }).catch(error => {
      console.log(error.response);
    })
    event.preventDefault(); 
  }

  createTable = (tableData) => {
    /**
     * create Table:
     * call createTableBody function to get table body
     * return table 
     */
    return (
      <table>
        {this.createTableBody(tableData)}
      </table>
    )
  }

  createTableBody = (tableData) => {
    /**
     * create table body:
     * two columns of data table
     * attributes in colum instead of row
     */
    var oddcol = [];
    var evencol = [];
    var oddrow = [];
    var evenrow = [];
    var count = 0;
    for(const [index, value] of Object.entries(tableData)) {
      if(count % 2 === 0) {
        oddcol.push(<th>{index}</th>);
        oddrow.push(<td>{Object.values(value)}</td>);
      } else {
        evencol.push(<th>{index}</th>);
        evenrow.push(<td>{Object.values(value)}</td>);
      }
      count = count + 1;
    }
    return (
      <tbody>
        <tr>
          {oddcol}
        </tr>
        <tr>
          {oddrow}
        </tr>
        <tr>
          {evencol}
        </tr>
        <tr>
          {evenrow}
        </tr>
      </tbody>
    )
  } 

  prepareData = (data, attributes) => {
    /**
     * create all sets of 2d array data
     * return promise(dictionary of data array)
     */
    return new Promise(function(resolve, reject){
      var prepare_data={};
      const attributes = Object.keys(data[0]);
      // creat list of attributes header
      for (const [index, value] of attributes.entries()) {
        if (value === 'barcode' || value === 'published_timestamp' || value ==='date') {
          continue;
        } else {
          prepare_data[value] = [['time', value]];
        }
      }
      for(var i=0; i < data.length; i++) {
        const current = data[i];
        const dataTime = new Date(current.published_timestamp.S);
        Object.keys(current).map((key,i) => {
          const value = current[key];
          if (key !== 'barcode' && key !== 'published_timestamp' && key !== 'date') {
            prepare_data[key].push([dataTime, parseFloat(value.N)]);
          }
        })
      }
      console.log(prepare_data);
      resolve(prepare_data);
    });
  }

  drawChart = (data) => {    
    /**
     * draw chart:
     * using google chart
     * given data, a dictionary with keys and data
     * return all the chart 
     */
    return (
      Object.keys(data).map( (key,i) => 
        <Chart key={key.toString()}
          width={'900px'}
          height={'600px'}
          chartType="LineChart"
          loader={<div>loading data</div>}
          data={data[key]}
          options={{
            title: 'Product ' + key + ' Performance',
            legend: { position: 'bottom' },
            //colors: ["#E37400"],
            explorer: { 
                actions: ['dragToZoom', 'rightClickToReset'],
                axis: 'horizontal',
                keepInBounds: true,
                maxZoomIn: 4.0
            },
            hAxis: {
              title: 'Time',
            },
            vAxis: {
              title: key,
            }
          }}
          rootProps={{ 'data-testid': '1' }}
        />
      )
    )
  }

  createOptions = (attributes) => {
    /**
     * create Options
     * based on attributes 
     * return a list of select options except barcode. published timestamp and date
     */
    return (
      attributes.map((a) => {
        if(a !== 'barcode' && a !== 'published_timestamp' && a !== 'date') {
          return <option value={a} key={a.toString()}>{a}</option>
        }
      })
    )
  }

  createFeatureSelection = (options) => {
    /**
     * create Featire selection
     * return feature selection function with a list of options according to attributes
     */
    return (
      <div className="query">
        <label>Select features you want to draw data: 
          <select multiple={true} onChange={this.handleFeatureSelection}>
            {options}
          </select>
          <div id="selected"></div>
        </label>
        <button onClick={this.showChart}>Show Chart</button>
      </div>
    )
  }

  showChart = () => {
    /**
     * show chart:
     * retrieve prepared data according to the requested features
     * call drawchart function return a list of charts
     * DOM render charts
     */
    var data = {};
    const features = this.state.features;
    console.log(features);
    features.map((f) => {
      const d = this.state.data[f];
      data[f] = d;
    });
    console.log(data);
    const charts = this.drawChart(data);
    ReactDOM.render(charts, document.getElementById('chart'));
  }


  render() {
    return (
      // <Router>
      //    <Switch>
      //     <Route path="/login">
      //       <Login />
      //     </Route>
      //     <Route path="/">
      //       <Dashboard {...this.props}/>
      //     </Route>
      //   </Switch>
      // </Router>

      <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}
 
class MySignIn extends SignIn {
  render() {
    return(
      
      <Row className="pt-5">
        <Col md="5"></Col>
        <Col>
          <Container>
            <Card>
              <CardHeader>
                <strong>Sign In</strong>
              </CardHeader>
              <CardBody>
                <Col col="6" sm="4" md="2" xl className="mb-3 mb-xl-0">
                  <Button block color="primary" onClick={() => Auth.federatedSignIn()}>Sign in With SSO</Button>
                </Col>
              </CardBody>
            </Card>
          </Container>
        </Col>
        <Col md="5"></Col>
      </Row>

    )
  }
}

const AppWithAuth = withAuthenticator(App,false, [
  <MySignIn/>
  ]);


export default () => (
  <ApolloProvider client={client}>
    <Rehydrated>
      <AppWithAuth />
    </Rehydrated>
  </ApolloProvider>
); 
