import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Amplify
// { PubSub } 
from 'aws-amplify';
import config from './aws-exports';
import store from './views/redux/store';

// var getUrl = window.location;
// var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
//  console.log(baseUrl)
// config.Auth = {
//     region: "us-east-1",
//     userPoolId: "us-east-1_xXndwD9SL",
//     userPoolWebClientId: "shcsku0u7fu6sdsgaa5pc6j6c",
//     identityPoolId: "us-east-1:9f152e43-3e03-4bb2-ab44-e7d399546217",
//     oauth: {
//       domain: "https://bziotdash.auth.us-east-1.amazoncognito.com",
//       scope: ['email', 'openid'],
//       redirectSignIn: "http://localhost:3000/login",
//       redirectSignOut: "http://localhost:3000/logout",
//       responseType: 'code',
//     },
//   };

config.oauth = {
    domain: "bzfactory-prod.auth.us-east-1.amazoncognito.com",
    scope: ['email', 'openid'],
    redirectSignIn: 'https://bzf.glidewell.ai',
    redirectSignOut: 'https://bzf.glidewell.ai',
    // redirectSignIn: 'http://localhost:3000',
    // redirectSignOut: 'http://localhost:3000',
    responseType: 'code'
}

Amplify.configure(config)

ReactDOM.render(
<Provider store={store}>
<App />
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
