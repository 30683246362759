import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import Amplify, {Auth, Hub} from 'aws-amplify';

Auth.configure({

    domain: "https://bzfactory-prod.auth.us-east-1.amazoncognito.com",
    scope: ['email', 'openid'],
    redirectSignIn: 'https://bzf.glidewell.ai/login',
    redirectSignOut: 'https://bzf.glidewell.ai/logout',
    // redirectSignIn: "http://localhost:3000/login",
    // redirectSignOut: "http://localhost:3000/logout",
    responseType: 'code'

});

class Login extends Component {

  constructor(props){
    super(props);
    Hub.listen('auth', (data) => {
      console.log(data);
        switch (data.payload.event) {
            case 'signIn':
                console.log(data.payload.data)
                this.setState({authState: 'signedIn', authData: data.payload.data});
                break;
            case 'signIn_failure':
                console.log(data.payload.data)
                this.setState({authState: 'signIn', authData: null, authError: data.payload.data});
                break;
            default:
                break;
        }
    });

    // AWSMobileClient.sharedInstance().federatedSignIn(providerName:"", token: ""){}
    //const user = Auth.currentAuthenticatedUser();
  }

  validateUserSession() {
    const {
      history
    } = this.props;

    Auth.currentAuthenticatedUser()
      .then(currentAuthUser => {
        console.log('Redirect.validateUserSession():Auth.currentAuthenticatedUser() currentAuthUser:', currentAuthUser);
        // grab the user session
        Auth.userSession(currentAuthUser)
          .then(session => {
            console.log('Redirect.validateUserSession():Auth.userSession() session:', session);
            // finally invoke isValid() method on session to check if auth tokens are valid
            // if tokens have expired, lets call "logout"
            // otherwise, dispatch AUTH_USER success action and by-pass login screen
            if (session.isValid()) {
              // fire user is authenticated
              console.log('user session is valid!');

              this.setState({
                signedIn: true,
                errorMessage: '',
                accessToken: session.accessToken.jwtToken,
                idToken: session.idToken.jwtToken,
                refreshToken: session.refreshToken.token
              });

              history.push('/main', { signedIn: true, authenticated: true });
            } else {
              // fire user is unauthenticated
              const errorMessage = 'user session invalid. auth required';

              this.setState({
                signedIn: false,
                errorMessage,
                accessToken: '',
                idToken: '',
                refreshToken: ''
              });

              console.log(errorMessage);
              history.push('/signin', { signInFailure: true, errorMessage, authenticated: false });
            }
          })
          .catch(err => {
            const errorMessage = JSON.stringify(err);

            this.setState({
              signedIn: false,
              errorMessage,
              accessToken: '',
              idToken: '',
              refreshToken: ''
            });

            console.error('Redirect.validateUserSession():Auth.userSession() err:', err);
            history.push('/signin', { signInFailure: true, errorMessage, authenticated: false });
          });
      })
      .catch(err => {
        const errorMessage = JSON.stringify(err);

        this.setState({
          signedIn: false,
          errorMessage,
          accessToken: '',
          idToken: '',
          refreshToken: ''
        });

        console.error('Redirect.validateUserSession():Auth.currentAuthenticatedUser() err:', err);
        history.push('/signin', { signInFailure: true, errorMessage, authenticated: false });
      });
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <button onClick={() => Auth.federatedSignIn()}>Beep</button>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="Username" autoComplete="username" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Password" autoComplete="current-password" />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4">Login</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0">Forgot password?</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Sign up</h2>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.</p>
                      <Link to="/register">
                        <Button color="primary" className="mt-3" active tabIndex={-1}>Register Now!</Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
